<div class="main">
  <mat-drawer-container>
    <mat-drawer
      [mode]="drawerMode"
      [opened]="opened"
      (closedStart)="opened = false"
    >
      <div class="sidenav-container">
        <div class="logo-box">
          <img src="assets/images/logo.png" alt="" srcset="" />
          <h1 class="app-name">{{ "app_name" | translate }}</h1>
          <span class="divider"></span>
        </div>
        <div class="menu-wrapper">
          <mat-list class="main-menu">
            @if (menu.dashboard.length > 0) {
              <a [routerLink]="menu.dashboard[0].route">
                <mat-list-item matRipple routerLinkActive="active-route">
                  <mat-icon svgIcon="home"></mat-icon>
                  <span>{{
                    "routes." + menu.dashboard[0].title | translate
                  }}</span>
                </mat-list-item>
              </a>
            }
            @if (menu.annual_investment_plans.length > 0) {
              <a [routerLink]="menu.annual_investment_plans[0].route">
                <mat-list-item matRipple routerLinkActive="active-route">
                  <mat-icon svgIcon="investment_plan"></mat-icon>
                  <span>{{
                    "routes." + menu.annual_investment_plans[0].title
                      | translate
                  }}</span>
                </mat-list-item>
              </a>
            }
            @if (menu.investment_portfolios.length > 0) {
              <a [routerLink]="menu.investment_portfolios[0].route">
                <mat-list-item matRipple routerLinkActive="active-route">
                  <mat-icon svgIcon="wallet"></mat-icon>
                  <span>{{
                    "routes." + menu.investment_portfolios[0].title | translate
                  }}</span>
                </mat-list-item>
              </a>
            }
            @if (menu.investment_funds.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="dollar_cycle"></mat-icon>
                    <span>{{
                      "route_types." + menu.investment_funds[0].type | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.investment_funds; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.investment_requests.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="request"></mat-icon>
                    <span>{{
                      "route_types." + menu.investment_requests[0].type
                        | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.investment_requests; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.payment_requests.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="account"></mat-icon>
                    <span>{{
                      "route_types." + menu.payment_requests[0].type | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.payment_requests; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.investment_management.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="invest_dollar"></mat-icon>
                    <span>{{
                      "route_types." + menu.investment_management[0].type
                        | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.investment_management; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.accounting.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="accountant"></mat-icon>
                    <span>{{
                      "route_types." + menu.accounting[0].type | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.accounting; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.asset_and_liability_management.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="invest_dollar"></mat-icon>
                    <span>{{
                      "route_types." +
                        menu.asset_and_liability_management[0].type | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (
                  item of menu.asset_and_liability_management;
                  track $index
                ) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.reports.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="carbon_report"></mat-icon>
                    <span>{{
                      "route_types." + menu.reports[0].type | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.reports; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.audit.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="request"></mat-icon>
                    <span>{{
                      "route_types." + menu.audit[0].type | translate
                    }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                @for (item of menu.audit; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
            @if (menu.settings.length > 0) {
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon svgIcon="settings"></mat-icon>
                    <span>{{
                      "route_types." + menu.settings[0].type | translate
                    }}</span>
                  </mat-panel-title></mat-expansion-panel-header
                >
                @for (item of menu.settings; track $index) {
                  <a [routerLink]="item.route">
                    <mat-list-item
                      matRipple
                      class="expand-panel-item"
                      routerLinkActive="active-route"
                    >
                      <span>{{ "routes." + item.title | translate }}</span>
                    </mat-list-item>
                  </a>
                }
              </mat-expansion-panel>
            }
          </mat-list>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <mat-toolbar>
        <mat-icon
          (click)="toggleMenu()"
          color="primary"
          class="menu-toggle"
          [class.flipped]="!opened"
          >menu_open</mat-icon
        >
        <div class="container-fluid">
          <div
            class="full-width d-flex flex-row align-items-center justify-content-end"
          >
            <!-- @if (insufficientRight()) {
              <div class="d-flex flex-row align-items-center me-5 error-block">
                <mat-icon class="me-1">report</mat-icon>
                <span>{{ "errors.InsufficientRights" | translate }}</span>
              </div>
            } -->

            <mat-form-field class="default-input no-sub" appearance="outline">
              <mat-select
                class="custom-select"
                [value]="selectedScheme"
                (selectionChange)="schemeChangeHandler($event)"
              >
                @for (scheme of schemes; track scheme) {
                  <mat-option [value]="scheme">{{
                    "schemes." + scheme.code | translate
                  }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <div
              class="profile-box d-flex flex-row align-items-center ms-2"
              [matMenuTriggerFor]="profileMenu"
            >
              <div class="profile">
                <img src="assets/images/user.png" alt="profile" srcset="" />
              </div>
              <div class="user-info d-flex flex-column">
                <div class="user-name">{{ username }}</div>
                <div class="user-role">{{ roleName }}</div>
              </div>
              <mat-icon class="btn-arrow-down">keyboard_arrow_down</mat-icon>
              <mat-menu #profileMenu="matMenu" class="custom-menu">
                <!-- <button mat-menu-item (click)="userChangePwdHandler()">
                  <mat-icon>lock_reset</mat-icon>
                  <span>{{ "actions.change_pwd" | translate }}</span>
                </button> -->
                <button mat-menu-item (click)="logoutHandler()" class="error">
                  <mat-icon>logout</mat-icon>
                  <span>{{ "actions.logout" | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-toolbar>
      <div class="app-container">
        <router-outlet />
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
