import {
  Component,
  computed,
  HostListener,
  OnInit,
  Signal,
} from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MENUITEMS } from '../../app.routes';
import { MenuItem } from '../../models/menu-item';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageEnum } from '../../models/enums/local-storage-enum';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { BaseDialogData } from '../../models/bases/base-dialog-data';
import { UserChangePwdFormComponent } from '../../routes/users/components/user-change-pwd-form/user-change-pwd-form.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SingleSignOnService } from '../../services/single-sign-on.service';
import { BaseRequestService } from '../../services/base-request.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { SchemeService } from '../../services/scheme.service';
import { Utils } from '../../utils/utils';
import { Scheme } from '../../models/scheme';

@Component({
  selector: 'app-main-container',
  standalone: true,
  imports: [
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
  ],
  templateUrl: './main-container.component.html',
  styleUrl: './main-container.component.scss',
})
export class MainContainerComponent implements OnInit {
  username: string;
  roleName: string;
  opened: boolean = true;
  menu: any = {};
  settingsMenu: MenuItem[];
  desktopViewWidth: number = 1100;
  drawerMode: 'over' | 'side' = 'side';
  insufficientRight: Signal<boolean>;
  schemes: Array<Scheme> = [];
  selectedScheme: Scheme;

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _router: Router,
    private _dialog: MatDialog,
    private _singleSignOnService: SingleSignOnService,
    private _baseRequestService: BaseRequestService,
    private _schemeService: SchemeService,
    private _utils: Utils,
  ) {
    iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    iconRegistry.addSvgIcon(
      'home',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/home.svg'),
    );
    iconRegistry.addSvgIcon(
      'about',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/about.svg'),
    );
    iconRegistry.addSvgIcon(
      'account',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/account.svg'),
    );
    iconRegistry.addSvgIcon(
      'accountant',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/accountant.svg'),
    );
    iconRegistry.addSvgIcon(
      'carbon_report',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/carbon_report.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'dollar_cycle',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/dollar_cycle.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'investment_plan',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/investment-plan.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'invest_dollar',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/invest_dollar.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'request',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/request.svg'),
    );
    iconRegistry.addSvgIcon(
      'settings',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/solar-settings-linear.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'rotated_send',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/icons/rotated-send.svg',
      ),
    );
    iconRegistry.addSvgIcon(
      'wallet',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/wallet.svg'),
    );
    this.insufficientRight = computed(() => {
      return this._baseRequestService.hasPermissionError();
    });
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    if (width < this.desktopViewWidth) {
      this.drawerMode = 'over';
      if (this.opened) {
        this.opened = false;
      }
    } else {
      this.drawerMode = 'side';
      if (!this.opened) {
        this.opened = true;
      }
    }
  }

  onMenuItemClick(event: MouseEvent, route: string): void {
    if (event.ctrlKey || event.metaKey) {
      const url =
        window.location.origin +
        this._router.serializeUrl(this._router.createUrlTree([route]));
      window.open(url, '_blank');
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.username = this._localStorageService.get(LocalStorageEnum.username);
    this.roleName = this._localStorageService.get(
      LocalStorageEnum.user_role_name,
    );
    const permissions = this._authService.permissions;
    this.getSchemes();
    this.menu = {
      settings: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'settings' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      audit: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'audit' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      dashboard: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'dashboard' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      annual_investment_plans: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'annual-investment-plans' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      investment_portfolios: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'investment-portfolios' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      investment_funds: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'investment-funds' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      investment_requests: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'investment-requests' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      asset_and_liability_management: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'bank-data' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      payment_requests: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'payment-requests' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      investment_management: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'investment-management' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      accounting: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'accounting' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      reports: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'reports' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
      about_us: MENUITEMS.filter((menuItem: MenuItem) => {
        return menuItem.type === 'about-us';
      }),
      compare_growth_rates: MENUITEMS.filter((menuItem: MenuItem) => {
        return (
          menuItem.type === 'compare-growth-rates' &&
          menuItem.permissions?.find((permission) =>
            permissions.includes(permission),
          )
        );
      }),
    };
    if (this._router.url === '/') {
      if (!this._authService.isAuth) {
        this._router.navigate(['/login'], {
          replaceUrl: true,
        });
      } else {
        const firstAvailableMenu = MENUITEMS.find((item: MenuItem) => {
          return item.permissions?.find((permission) =>
            permissions.includes(permission),
          );
        });
        if (firstAvailableMenu) {
          this._router.navigate([firstAvailableMenu.route], {
            replaceUrl: true,
          });
        } else {
          this._router.navigate(['/no-permission'], {
            replaceUrl: true,
          });
        }
      }
    } else if (this._router.url === '/no-permission') {
      const firstAvailableMenu = MENUITEMS.find((item: MenuItem) => {
        return item.permissions?.find((permission) =>
          permissions.includes(permission),
        );
      });
      if (firstAvailableMenu) {
        this._router.navigate([firstAvailableMenu.route], {
          replaceUrl: true,
        });
      }
    }
  }

  getSchemes = async () => {
    try {
      const res = await this._schemeService.getSchemes({
        noPagination: true,
      });
      if ((res && this, this._utils.isData<Scheme[]>(res))) {
        this.schemes = res;
        this.selectedScheme = this.schemes.find(
          (scheme) =>
            scheme._id ===
            this._localStorageService.get(LocalStorageEnum.current_scheme),
        );
        if (!this.selectedScheme) {
          this._localStorageService.set(
            LocalStorageEnum.current_scheme,
            this.schemes[0]._id,
          );
          this._localStorageService.set(
            LocalStorageEnum.current_scheme_code,
            this.schemes[0].code,
          );
          this.selectedScheme = this.schemes[0];
        }
      } else {
        this.schemes = [];
      }
    } catch (error) {
      console.error('getSchemeList error->', error);
      this.schemes = [];
    }
  };

  schemeChangeHandler(event: MatSelectChange) {
    this._localStorageService.set(
      LocalStorageEnum.current_scheme,
      event.value?._id,
    );
    this._localStorageService.set(
      LocalStorageEnum.current_scheme_code,
      event.value?.code,
    );
    window.location.reload();
  }

  toggleMenu() {
    this.opened = !this.opened;
  }

  userChangePwdHandler = async () => {
    try {
      const dialogData: BaseDialogData = {
        isForUpdate: false,
      };
      const dialogRef = this._dialog.open(UserChangePwdFormComponent, {
        width: '600px',
        disableClose: true,
        data: dialogData,
        panelClass: 'custom-dialog',
      });
      const result = await lastValueFrom(dialogRef.afterClosed());
      if (result && result.succeeded) {
        this._authService.logout();
      } else {
        console.error('user change pwd error');
      }
    } catch (error) {
      // this._messageService.showError(error);
      console.error('user change pwd error->', error);
    }
  };

  logoutHandler() {
    ///TODO not yet implement
    // this._authService.logout();
    // this._router.navigateByUrl('/login');
    this._singleSignOnService.logout();
    this._authService.logout();
  }
}
